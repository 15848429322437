<template>

  <div class="quote__page">
    <div style="height: 0; opacity: 0; overflow: hidden; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="height:60px;object-fit:contain;"
                      :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                      alt="Logo"
                      crossorigin="anonymous"
                    >
                  </div>
                </div>
                <div
                  style="min-width: fit-content; width: fit-content;"
                  class="px-2"
                >
                  <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                    Quotation Comparison and Approval Form
                  </h4>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    {{ purchaseRequest.caseID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Prepared By:</span> {{ quotation.createdBy ? quotation.createdBy.name : '' }}, {{ quotation.createdBy ? quotation.createdBy.position : '' }}, {{ quotation.createdBy ? (quotation.createdBy.department.length ? quotation.createdBy.department[0].name : '') : '' }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(quotation.createdAt) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  class="p-0 item__description_table"
                >
                  <b-table-simple
                    v-if="chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length"
                    ref="teamsTable"
                    class="position-relative has_padding quote__table borderless__table"
                    responsive
                    caption-top
                  >
                    <b-thead head-variant="dark">
                      <b-tr
                        v-if="comparedVendors"
                        class="group__tag group__tag__white_bg"
                      >
                        <b-th
                          class="empty"
                          colspan="4"
                        >
                          <table class="qcf-table-header">
                            <tbody>
                              <tr>
                                <td>
                                  <span class="text-bold-black">Company</span>
                                </td>
                                <td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <span class="text-bold-black">Justification</span>
                                </td>
                                <td>{{ purchaseRequest.purpose }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <span class="text-bold-black">Department</span>
                                </td>
                                <td>{{ purchaseRequest.department ? purchaseRequest.department.name : '' }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </b-th>
                        <b-th
                          v-for="(vndr, index) in comparedVendors"
                          :key="index"
                          class="group__tag_info"
                          colspan="3"
                        >
                          <b-badge
                            :variant="vndr.status == 'approved' ? 'light-success' : 'light-warning'"
                            class="text-capitalize"
                          >
                            {{ vndr.status == 'approved' ? 'Approved Vendor' : (vndr.type == 1 ? 'Not In Vendor List' : 'Not In Vendor List') }}
                          </b-badge>
                          <span class="vn__name">{{ vndr.name }}</span>
                          <span class="vn__gst">{{ vndr.code }}</span>
                        </b-th>
                      </b-tr>

                      <b-tr class="group__tag">
                        <b-th style="border-left: 1px solid #e7edf5 !important;">
                          No
                        </b-th>
                        <b-th>
                          Item Description
                        </b-th>
                        <b-th>
                          Qty
                        </b-th>
                        <b-th style="border-right: 1px solid #e7edf5 !important;">
                          Unit
                        </b-th>
                        <b-th
                          v-for="(vndr, index) in comparedVendors"
                          :key="index"
                          class="group__tag_info"
                          colspan="3"
                          style="border-right: 1px solid #e7edf5 !important;"
                        >
                          <span class="mr-50 unit_price">u. price($)</span>
                          <span class="mr-50 total_price">TotaL($)</span>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody
                      v-if="comparedVendors && chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length"
                      class="white-body-background"
                    >
                      <b-tr
                        v-for="(item, index) in chunksOfItemRequests[pg - 1]"
                        :key="index"
                      >
                        <b-td style="border-right: none !important;">
                          {{ resolveItemCounter(pg, index) }}
                        </b-td>
                        <b-td style="border-right: none !important; max-width: 15rem;">
                          <span class="text-bold-black">{{ item.itemsMaster }}</span>
                          <br>
                          <span class="text-pre">{{ item.description }}</span>
                        </b-td>
                        <b-td style="border-right: none !important;">
                          {{ item.quantity }}
                        </b-td>
                        <td>
                          {{ item.unit }}
                        </td>
                        <b-td
                          v-for="(vndr, key) in comparedVendors"
                          :key="key"
                          class="group__tag_info "
                          colspan="3"
                        >
                          <span class="unit_price">{{ item.unitCost[key] }}</span>
                          <span class="unit_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                          <b-badge
                            v-if="item.gst[key] == 'yes'"
                            variant="secondary"
                            style="background:rgba(186, 191, 199, 0.12); color:#000000;"
                          >
                            GST
                          </b-badge>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot v-if="(pg == (pdfTotalPage - 1) && !chunksOfItemRequests[pg - 1]) || (pg == pdfTotalPage && chunksOfItemRequests[pg - 1])">
                      <tr class="cal_row subtotal_row">
                        <td
                          colspan="4"
                          style="background-color: #F5F8FB !important;"
                        />
                        <b-td
                          v-for="(vndr, key) in comparedVendors"
                          :key="key"
                          class="group__tag_info "
                          colspan="3"
                          style="padding-left: 8px !important; padding-top: 5px !important; background-color: #F5F8FB !important;"
                        >
                          <span class="mr-50 semi-bold">Subtotal</span> <span class="mr-50">{{ numberFormat(getSubTotal(key)) }}</span><br>
                          <span class="mr-50 semi-bold">GST</span> <span class="mr-50">{{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${gstList[key]}%)`) }}</span> <br>
                          <span class="mr-50 semi-bold">Grand Total</span> <span class="mr-50 semi-bold">{{ numberFormat(getGrandTotal(key)) }}</span>
                        </b-td>
                      </tr>
                      <!-- <tr class="cal_row">
                        <td />
                        <td colspan="3">
                          <span class="semi-bold">Payment terms</span>
                        </td>
                        <b-td
                          v-for="(vndr, key) in comparedVendors"
                          :key="key"
                          class="group__tag_info"
                          colspan="3"
                        >
                          {{ paymentTerms[key] }}<br>
                          <b-badge
                            v-if="(vndr.id + '|//|' + vndr.name) == recommendedProvider"
                            variant="secondary"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="mr-25"
                            />
                            <span>Recommended</span>
                          </b-badge>
                        </b-td>
                      </tr> -->
                    </b-tfoot>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row style="font-size: 11px;">
                  <b-col
                    cols="4"
                    md="4"
                  >
                    <span class="text-bold-black">Recommended External Provider</span>
                    <br>
                    <span>{{ resolveRecommendedProviderName() }}</span>
                  </b-col>
                  <b-col
                    cols="8"
                    md="8"
                  >
                    <span class="text-bold-black">Remarks</span>
                    <br>
                    <span class="text-pre">{{ remarks }}</span>
                  </b-col>
                </b-row>
              </b-card-body>
              <div :style="{ height: gapHeight+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row>
                  <b-col
                    v-for="(signatory, index) of resolveSignatoryAssigns"
                    :key="index"
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text
                      v-if="(signatoryOption > 1 && signatoryOption > (index + 1)) || (signatoryOption == 1 && signatoryOption > index)"
                      class="mb-0"
                    >
                      <span class="font-weight-bold">{{ loas[index].code }}</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ resolveSignatoryName(signatory) }}
                      </p>
                      <p class="ml-75">
                        {{ resolveSignatoryDepartment(signatory) }}
                      </p>
                    </b-card-text>
                  </b-col>
                  <b-col
                    v-if="signatoryOption > 1"
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">Approved by</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ resolveSignatoryName(signatoryAssigns[signatoryOption - 1]) }}
                      </p>
                      <p class="ml-75">
                        {{ resolveSignatoryDepartment(signatoryAssigns[signatoryOption - 1]) }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </div>
          <!-- <div
            v-if="pg != pdfTotalPage"
            class="html2pdf__page-break"
          /> -->
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="height:60px;object-fit:contain;"
                  :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                  alt="Logo"
                  crossorigin="anonymous"
                >
              </div>
              <div
                style="min-width: fit-content; width: fit-content;"
                class="px-2"
              >
                <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                  Quotation Comparison and Approval Form
                </h4>
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ purchaseRequest.caseID }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Prepared By </span> {{ quotation.createdBy ? quotation.createdBy.name : '' }}, {{ quotation.createdBy ? quotation.createdBy.position : '' }}, {{ quotation.createdBy ? (quotation.createdBy.department.length ? quotation.createdBy.department[0].name : '') : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            id="content-div"
            class="invoice-padding pt-0 main__body"
          >
            <b-row class="invoice-spacing">
              <b-col
                cols="12"
                class="p-0 item__description_table"
              >
                <b-table-simple
                  ref="teamsTable"
                  class="position-relative has_padding quote__table borderless__table"
                  responsive
                  caption-top
                >
                  <b-thead head-variant="dark">
                    <b-tr
                      v-if="comparedVendors"
                      id="main-table-width"
                      class="group__tag group__tag__white_bg"
                    >
                      <b-th
                        class="empty"
                        colspan="4"
                      >
                        <table class="qcf-table-header">
                          <tbody>
                            <tr>
                              <td>
                                <span class="text-bold-black">Company</span>
                              </td>
                              <td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</td>
                            </tr>
                            <tr>
                              <td>
                                <span class="text-bold-black">Justification</span>
                              </td>
                              <td>{{ purchaseRequest.purpose }}</td>
                            </tr>
                            <tr>
                              <td>
                                <span class="text-bold-black">Department</span>
                              </td>
                              <td>{{ purchaseRequest.department ? purchaseRequest.department.name : '' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </b-th>
                      <b-th
                        v-for="(vndr, index) in comparedVendors"
                        :key="index"
                        class="group__tag_info"
                        colspan="3"
                      >
                        <b-badge
                          :variant="vndr.status == 'approved' ? 'light-success' : 'light-warning'"
                          class="text-capitalize"
                        >
                          {{ vndr.status == 'approved' ? 'Approved Vendor' : (vndr.type == 1 ? 'Not In Vendor List' : 'Not In Vendor List') }}
                        </b-badge>
                        <span class="vn__name">{{ vndr.name }}</span>
                        <span class="vn__gst">{{ vndr.code }}</span>
                      </b-th>
                    </b-tr>

                    <b-tr class="group__tag">
                      <b-th style="border-left: 1px solid #e7edf5 !important;">
                        No
                      </b-th>
                      <b-th>
                        Item Description
                      </b-th>
                      <b-th>
                        Qty
                      </b-th>
                      <b-th style="border-right: 1px solid #e7edf5 !important;">
                        Unit
                      </b-th>
                      <b-th
                        v-for="(vndr, index) in comparedVendors"
                        :key="index"
                        class="group__tag_info"
                        colspan="3"
                        style="border-right: 1px solid #e7edf5 !important;"
                      >
                        <span class="mr-50 unit_price">u. price($)</span>
                        <span class="mr-50 total_price">TotaL($)</span>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody
                    v-if="comparedVendors && chunkItemRequests.length"
                    class="white-body-background"
                  >
                    <b-tr
                      v-for="(item, index) in chunkItemRequests"
                      :key="index"
                    >
                      <b-td style="border-right: none !important;">
                        {{ index + 1 }}
                      </b-td>
                      <b-td style="border-right: none !important; max-width: 15rem;">
                        <span class="text-bold-black">{{ item.itemsMaster }}</span>
                        <br>
                        <span class="text-pre">{{ item.description }}</span>
                      </b-td>
                      <b-td style="border-right: none !important;">
                        {{ item.quantity }}
                      </b-td>
                      <td>
                        {{ item.unit }}
                      </td>
                      <b-td
                        v-for="(vndr, key) in comparedVendors"
                        :key="key"
                        class="group__tag_info "
                        colspan="3"
                      >
                        <span class="unit_price">{{ item.unitCost[key] }}</span>
                        <span class="unit_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                        <b-badge
                          v-if="item.gst[key] == 'yes'"
                          variant="secondary"
                          style="background:rgba(186, 191, 199, 0.12); color:#000000;"
                        >
                          GST
                        </b-badge>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot id="tableFooter">
                    <tr class="cal_row subtotal_row">
                      <td
                        colspan="4"
                        style="background-color: #F5F8FB !important;"
                      />
                      <b-td
                        v-for="(vndr, key) in comparedVendors"
                        :key="key"
                        class="group__tag_info "
                        colspan="3"
                        style="padding-left: 8px !important; padding-top: 5px !important; background-color: #F5F8FB !important;"
                      >
                        <span class="mr-50 semi-bold">Subtotal</span> <span class="mr-50">{{ numberFormat(getSubTotal(key)) }}</span><br>
                        <span class="mr-50 semi-bold">GST</span> <span class="mr-50">{{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${gstList[key]}%)`) }}</span><br>
                        <span class="mr-50 semi-bold">Grand Total</span> <span class="mr-50 semi-bold">{{ numberFormat(getGrandTotal(key)) }}</span>
                      </b-td>
                    </tr>
                    <!-- <tr class="cal_row">
                      <td />
                      <td colspan="3">
                        <span class="semi-bold">Payment terms</span>
                      </td>
                      <b-td
                        v-for="(vndr, key) in comparedVendors"
                        :key="key"
                        class="group__tag_info"
                        colspan="3"
                      >
                        {{ paymentTerms[key] }}<br>
                        <b-badge
                          v-if="(vndr.id + '|//|' + vndr.name) == recommendedProvider"
                          variant="secondary"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-25"
                          />
                          <span>Recommended</span>
                        </b-badge>
                      </b-td>
                    </tr> -->
                  </b-tfoot>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <b-card-body
            id="footer-div"
            class="invoice-padding pb-0 footer__area"
          >
            <b-row style="font-size: 11px;">
              <b-col
                cols="4"
                md="4"
              >
                <span class="text-bold-black">Recommended External Provider</span>
                <br>
                <span>{{ resolveRecommendedProviderName() }}</span>
              </b-col>
              <b-col
                cols="8"
                md="8"
              >
                <span class="text-bold-black">Remarks</span>
                <br>
                <span class="text-pre">{{ remarks }}</span>
              </b-col>
            </b-row>
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                v-for="(signatory, index) of resolveSignatoryAssigns"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text
                  v-if="(signatoryOption > 1 && signatoryOption > (index + 1)) || (signatoryOption == 1 && signatoryOption > index)"
                  class="mb-0"
                >
                  <span class="font-weight-bold">{{ loas[index].code }}</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ resolveSignatoryName(signatory) }}
                  </p>
                  <p class="ml-75">
                    {{ resolveSignatoryDepartment(signatory) }}
                  </p>
                </b-card-text>
              </b-col>
              <b-col
                v-if="signatoryOption > 1"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Approved by</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ resolveSignatoryName(signatoryAssigns[signatoryOption - 1]) }}
                  </p>
                  <p class="ml-75">
                    {{ resolveSignatoryDepartment(signatoryAssigns[signatoryOption - 1]) }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>
    <div v-show="qcfPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Preview Quote Comparison Form
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <section class="invoice-preview-wrapper">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <div>
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ attachments ? ((attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        {{ purchaseRequest.caseID }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in attachments"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <p
                      v-show="currentFileIndex == 1"
                      class="mt-1"
                    >
                      Quotation Comparison Form (QCF)
                    </p>
                    <p
                      v-show="currentFileIndex != 1"
                      class="mt-1"
                    >
                      {{ attachments.length ? (attachments[currentFileIndex - 2] ? attachments[currentFileIndex - 2].description : '') : '' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (attachments ? (attachments.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div v-show="showAttachment">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
              <div v-show="!showAttachment">
                <iframe
                  id="docpdf"
                  width="100%"
                  height="1145"
                />
              </div>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="GridIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">Approval Required</span>
                  Ready to Submit?
                </h3>
              </template>

              <b-card-text class="mb-0">
                <span v-if="signatoryOption > 1">
                  If the information on this QCF is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ resolveNextSignatoryName() }}) for signing.
                </span>
                <span v-else>
                  If the information on this QCF is accurate, e-sign this form and it will be ready for Purchase Order creation by Central Purchasing.
                </span>
              </b-card-text>

              <b-form-checkbox
                v-model="secondAgree"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                I confirm that all information is accurate on this form.
              </b-form-checkbox>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="secondAgree == ''"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign and Submit Form</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </b-card>

            <b-card
              no-body
            >
              <b-card-header class="primary-card-header">
                <div class="d-flex align-items-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3 header-icon"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      class=""
                      size="20"
                    />
                  </b-button>
                  <h3 class="align-middle mr-2">
                    Budget
                  </h3>
                </div>

                <div
                  v-if="expenseItems.length"
                  class="d-flex align-items-center"
                >
                  <div
                    class="button-prev"
                  >
                    <b-button
                      :disabled="currentIndex == 0"
                      variant="none"
                      @click="currentIndex -= 1"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="20"
                      />
                    </b-button>
                  </div>
                  <div
                    slot="pagination"
                    class="swiper-pagination"
                  >
                    <span>{{ (currentIndex + 1).toString().padStart(2, 0) }} / {{ expenseItems.length.toString().padStart(2, 0) }}</span>
                  </div>
                  <div
                    class="button-next"
                  >
                    <b-button
                      :disabled="(currentIndex + 1) == expenseItems.length"
                      variant="none"
                      @click="currentIndex += 1"
                    >
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="20"
                      />
                    </b-button>
                  </div>
                </div>
              </b-card-header>
              <b-card-body>
                <b-badge
                  v-if="purchaseRequest.budgetStatus"
                  :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
                  class="text-capitalize-first mb-2"
                >
                  {{ purchaseRequest.budgetStatus }}
                </b-badge>
                <div
                  v-if="expenseItems.length"
                >
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Budgetary Period
                    </p>
                    <span>{{ expenseItems[currentIndex].budgetPeriod.name || '-' }} ({{ dateFormat(expenseItems[currentIndex].budgetPeriod.start) + ' - ' + dateFormat(expenseItems[currentIndex].budgetPeriod.end) }})</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Expense Type
                    </p>
                    <span>{{ expenseItems[currentIndex].expenseCategoryType || '-' }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Cost Centre
                    </p>
                    <span>{{ expenseItems[currentIndex].costCenter.name || '-' }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Sub-budget (Expense Category)
                    </p>
                    <span>{{ expenseItems[currentIndex].expenseCategory.code || '-' }} - {{ expenseItems[currentIndex].expenseCategory.name }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Balance
                    </p>
                    <span v-if="canViewThisAction('see-balance', 'BudgetOverview')">{{ expenseItems[currentIndex].subBudget ? (`S$ ${numberFormat(expenseItems[currentIndex].subBudget.remaining)}`) : '-' }}</span>
                    <span v-else>-</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Allocated Amount
                    </p>
                    <span>S$ {{ numberFormat(expenseItems[currentIndex].allocated) }}</span>
                  </div>
                </div>
                <div
                  v-else
                  class="row"
                >
                  <b-col>
                    <div class="empty_block">
                      <h4>Budget not allocated yet.</h4>
                      <b-button
                        v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
                        type="button"
                        variant="flat-primary"
                        :disabled="!purchaseRequest.requestType"
                        :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
                      >
                        <span class="align-middle">Allocate Budget</span>
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>

    <div v-show="!qcfPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="create-edit-form">
            <b-col cols="12">
              <h1 class="custom-header-title">
                Editing Quote Comparison Form {{ purchaseRequest.caseID }}
              </h1>
              <p class="log_info">
                Created by {{ quotation.createdBy ? quotation.createdBy.name : '' }} on {{ dateFormatWithTime(quotation.createdAt) }}<br>Last updated on {{ dateFormatWithTime(quotation.updatedAt) }}
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          class="content-header-right qcf-compare-vendor mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            v-b-modal.vendor-compare
            variant="success"
            @click="resetPreviousVendorData"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Compare Another Vendor</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="quotationCreateForm"
          #default="{invalid}"
        >
          <b-card
            ref="repeatCard"
            header-tag="header"
            no-body
          >
            <b-table-simple
              ref="teamsTable"
              class="position-relative has_padding quote__table borderless__table"
              responsive
              caption-top
            >
              <b-thead head-variant="dark">
                <b-tr class="group__tag group__tag__white_bg">
                  <b-th
                    style="width:55px; max-width:55px"
                    class="empty"
                  />
                  <b-th
                    style="width:266px; max-width:266px"
                    class="empty"
                  />
                  <b-th
                    style="width:86px; max-width:86px"
                    class="empty"
                  />
                  <b-th class="empty" />
                  <b-th
                    v-for="(vndr, index) in comparedVendors"
                    :key="index"
                    class="group__tag_info"
                    colspan="3"
                    style="width:260px; max-width:260px"
                  >
                    <b-badge
                      :variant="vndr.status == 'approved' ? 'light-success' : 'light-warning'"
                      class="text-capitalize"
                    >
                      {{ vndr.status == 'approved' ? 'Approved Vendor' : (vndr.type == 1 ? 'Not In Vendor List' : 'Not In Vendor List') }}
                    </b-badge>
                    <b-button
                      variant="flat-primary"
                      class="mt-0 mt-md-2 primary-button-with-background no-background"
                      type="button"
                      @click="removeVendor(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                      />
                    </b-button>
                    <b-button
                      variant="flat-primary"
                      class="mt-0 mt-md-2 primary-button-with-background no-background"
                      type="button"
                      @click="updateVendor(index)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-25"
                      />
                    </b-button>
                    <br>
                    <span class="vn__name">{{ vndr.name }}</span>
                    <span class="vn__gst">{{ vndr.code }}</span>
                  </b-th>
                </b-tr>

                <b-tr class="group__tag">
                  <b-th style="border-left: 1px solid #e7edf5 !important;" />
                  <b-th>
                    Item
                  </b-th>
                  <b-th>
                    Quantity
                  </b-th>
                  <b-th style="border-right: 1px solid #e7edf5 !important;">
                    Unit
                  </b-th>
                  <b-th
                    v-for="(vndr, index) in comparedVendors"
                    :key="index"
                    class="group__tag_info"
                    colspan="3"
                    style="border-right: 1px solid #e7edf5 !important;"
                  >
                    <span class="unit_price">
                      u. price($)
                    </span>
                    <span class="total_price">TotaL($)</span> <span v-if="vndr.code != 'No GST'">GST</span>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="white-body-background">
                <b-tr
                  v-for="(item, index) in itemRequests"
                  :key="index"
                >
                  <b-td>
                    <b-button
                      variant="flat-danger"
                      class="mt-0 mt-md-2 primary-button-with-background no-background"
                      type="button"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-td>
                  <b-td>
                    <validation-provider
                      #default="{ errors }"
                      name="item"
                      rules="required"
                    >
                      <b-form-group
                        :label-for="'item' + index"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <!-- <v-select
                          :id="'item' + index"
                          v-model="item.itemsMaster"
                          label="itemNo"
                          placeholder="Select an option"
                          :options="itemsMasterOptions"
                          :reduce="itemNo => itemNo._id"
                          :clearable="false"
                          @input="changeItemsMaster(index)"
                        /> -->
                        <!-- <vue-autosuggest
                          v-model="item.itemsMaster"
                          :suggestions="filteredOptions"
                          :get-suggestion-value="getSuggestionValue"
                          :input-props="{id: `autosuggest__input${index}`, class: 'form-control', placeholder: 'Type an item name'}"
                          @selected="(...args) => setMasterItem(...args, index)"
                          @input="suggestOnInputChange"
                        >
                          <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item.description }}</span>
                          </template>
                        </vue-autosuggest> -->

                        <div
                          class="d-flex justify-content-between search-bar"
                        >
                          <b-form-input
                            :id="'search_name'+index"
                            v-model="item.searchInput"
                            name="name"
                            placeholder="Type an item name"
                            autocomplete="off"
                            @keyup="submitSearchInput(index)"
                            @blur="closeSuggestionBox(index)"
                          />
                        </div>
                        <div v-show="item.searchInputResult">
                          <div
                            class="search-results mt-50"
                          >
                            <div>
                              <b-list-group
                                v-if="item.storeItemSearchInputData.length"
                                flush
                              >
                                <span class="found-text-block">Inventory Items</span>
                                <b-list-group-item
                                  v-for="(storeItem, sIndex) in item.storeItemSearchInputData"
                                  :key="sIndex"
                                  class="cursor-pointer"
                                  @mousedown="addSearchInputStoreItem(index, sIndex, storeItem._id)"
                                >
                                  <div class="d-flex">
                                    <b-img
                                      class="item-img-block"
                                      :src="storeItem.image ? storeItem.image : defaultImage"
                                    />
                                    <div
                                      class="search-item-text-block"
                                    >
                                      <h6>{{ storeItem.name }}</h6>
                                      <small class="text-muted">{{ storeItem.sku }}</small>
                                    </div>
                                  </div>
                                </b-list-group-item>
                              </b-list-group>
                              <b-list-group
                                v-if="item.masterItemSearchInputData.length"
                                flush
                                class="mt-1"
                              >
                                <span class="found-text-block">Suggestions from Purchasing</span>
                                <b-list-group-item
                                  v-for="(masterItem, mIndex) in item.masterItemSearchInputData"
                                  :key="mIndex"
                                  class="cursor-pointer"
                                  @mousedown="addSearchInputMasterItem(index, mIndex, masterItem._id)"
                                >
                                  <h6 style="margin-left: 5px;">
                                    {{ masterItem.description }}
                                  </h6>
                                </b-list-group-item>
                              </b-list-group>
                            </div>
                          </div>
                        </div>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <b-form-group
                      :label-for="'sku' + index"
                      class="item_description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="sku"
                        :vid="'sku' + index"
                      >
                        <b-form-input
                          :id="'sku' + index"
                          v-model="item.sku"
                          placeholder=""
                          :state="(errors.length > 0) ? false : null"
                          name="sku"
                          readonly
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      class="item_description"
                      :label-for="'description' + index"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        vid="description"
                      >
                        <b-form-textarea
                          :id="'description' + index"
                          v-model="item.description"
                          placeholder="Description"
                          :state="(errors.length > 0) ? false : null"
                          name="description"
                          trim
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-td>
                  <b-td>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="quantity"
                      rules="required"
                    >
                      <b-form-group
                        :label-for="'quantity' + index"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <v-select
                          :id="'quantity' + index"
                          v-model="item.quantity"
                          label="quantity"
                          placeholder="Select an option"
                          :options="quantities"
                          :clearable="false"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider> -->
                    <b-form-group
                      :label-for="'quantity' + index"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Quantity"
                        vid="quantity"
                        rules="required|float"
                      >
                        <b-form-input
                          :id="'quantity' + index"
                          v-model="item.quantity"
                          placeholder="0"
                          :state="(errors.length > 0) ? false : null"
                          name="quantity"
                          @keypress="validateNumberInput"
                          @paste="validateNumberPaste"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-td>
                  <td>
                    <validation-provider
                      #default="{ errors }"
                      name="unit"
                      rules="required"
                    >
                      <b-form-group
                        :label-for="'unit' + index"
                        :state="(errors.length > 0) ? false : null"
                        style="width:86px; max-width:86px"
                      >
                        <vue-autosuggest
                          v-model="item.unit"
                          :suggestions="filteredUnitOptions"
                          :get-suggestion-value="getUnitSuggestionValue"
                          :input-props="{id: `autosuggest__unit_input${index}`, class: 'form-control', placeholder: 'E.g. kg'}"
                          @selected="(...args) => setUnit(...args, index)"
                          @input="suggestOnInputChangeUnit"
                        >
                          <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item }}</span>
                          </template>
                        </vue-autosuggest>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </td>
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <span class="unit_price">
                      <b-form-group
                        :label-for="'unitCost' + index + key"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Unit Cost($)"
                          vid="unitCost"
                          rules="required|float"
                        >
                          <b-form-input
                            :id="'unitCost' + index + key"
                            v-model="item.unitCost[key]"
                            placeholder="Unit Cost($)"
                            :state="(errors.length > 0) ? false : null"
                            name="unitCost"
                            @keypress="validateNumberInput"
                            @paste="validateNumberPaste"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span class="total_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                    <b-form-checkbox
                      v-show="vndr.code != 'No GST'"
                      v-model="item.gst[key]"
                      value="yes"
                      unchecked-value="no"
                      class="custom-control-primary"
                    />
                  </b-td>
                </b-tr>
                <!-- <b-tr>
                  <b-td />
                  <b-td>
                    Delivery
                  </b-td>
                  <b-td>
                    1
                  </b-td>
                  <b-td />
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <b-form-group
                      :label-for="'deliveryCost' + key"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Unit Cost($)"
                        vid="deliveryCost"
                        rules=""
                      >
                        <b-form-input
                          :id="'deliveryCost' + key"
                          v-model="deliveryCost[key]"
                          placeholder="Unit Cost($)"
                          :state="(errors.length > 0) ? false : null"
                          name="deliveryCost"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td />
                  <b-td>
                    Discount
                  </b-td>
                  <b-td>
                    1
                  </b-td>
                  <b-td />
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <b-form-group
                      :label-for="'discountList' + key"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Unit Cost($)"
                        vid="discountList"
                        rules=""
                      >
                        <b-form-input
                          :id="'discountList' + key"
                          v-model="discountList[key]"
                          placeholder="Unit Cost($)"
                          :state="(errors.length > 0) ? false : null"
                          name="discountList"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-td>
                </b-tr> -->
                <tr>
                  <td />
                  <td>
                    <div class="add_items">
                      <!-- <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        @click="addItemToQuotation"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add Item from Masterlist</span>
                      </b-button> -->
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="addItemToQuotation"
                      >
                        <!-- <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        /> -->
                        <span>Add Item</span>
                      </b-button>
                    </div>
                  </td>
                  <b-td />
                  <b-td />
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  />
                </tr>
                <tr class="cal_row subtotal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">Subtotal</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    {{ numberFormat(getSubTotal(key)) }}
                  </b-td>
                </tr>
                <tr class="cal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">GST</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    {{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${gstList[key]}%)`) }}
                  </b-td>
                </tr>
                <tr class="cal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">Grand Total</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    {{ numberFormat(getGrandTotal(key)) }}
                  </b-td>
                </tr>
                <tr class="cal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">Payment terms</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <b-form-textarea
                      v-model="paymentTerms[key]"
                      trim
                      placeholder="Payment terms"
                    />
                  </b-td>
                </tr>
                <tr class="bottom__row">
                  <td />
                  <td
                    colspan="3"
                    style="padding-left:0!important"
                  >
                    <b-form-group
                      label="Recommended External Provider*"
                      label-for="h-event-recommendedProvider"
                      style="width:100%!important"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Recommended External Provider"
                        vid="recommendedProvider"
                        rules="required"
                      >
                        <v-select
                          id="h-event-recommendedProvider"
                          v-model="recommendedProvider"
                          label="companyName"
                          placeholder="Select from the list"
                          :options="recommendedVendorOptions"
                          :reduce="companyName => companyName.id"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </td>
                  <td
                    :colspan="3*comparedVendors.length"
                    style="background-color:transparent!important; padding-left: 20px!important; padding-right: 20px!important;"
                  >
                    <b-form-group
                      label="Remarks"
                      label-for="h-event-remarks"
                      style="width:100%!important"
                    >
                      <b-form-textarea
                        v-model="remarks"
                        trim
                        placeholder="Remarks"
                      />
                    </b-form-group>
                  </td>
                </tr>
              </b-tbody>
            </b-table-simple>
            <hr class="dividerHR mt-0">
          </b-card>

          <!-- <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                Supporting Documents, Files and Images
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop files here or click to upload
                    </h3>
                    <div class="subtitle">
                      File should not bigger than 5 mb. JPEG, PNG, or PDF only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card> -->

          <b-card
            header-tag="header"
            class="signatoryOption_block"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 2</span>
                Signatories
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6 no_of_sign">
                <validation-provider
                  #default="{ errors }"
                  name="Number of Signatory Required"
                  vid="signatoryOption"
                  rules="required"
                >
                  <b-form-group
                    label="Number of Signatory Required*"
                    label-for="h-activity-type-signatoryOption"
                    label-cols-md="5"
                    :state="(errors.length > 0 || signatoryOptionValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-signatoryOption"
                      ref="vSelectSignatoryOption"
                      v-model="signatoryOption"
                      label="title"
                      :options="signatoryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      @input="changeSignatory()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="signatoryOptionValidation"
                      class="text-danger"
                    >
                      {{ signatoryOptionError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6" />
            </b-row>
            <hr class="dividerHR">
            <div v-if="signatoryOption">
              <b-row
                v-for="(opt, key) in loas"
                :key="key"
              >
                <b-col
                  v-if="(signatoryOption > 1 && signatoryOption > (key + 1)) || (signatoryOption == 1 && signatoryOption > key)"
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    :vid="'event-custom-field-' + key"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="opt.title"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[key]"
                        label="name"
                        placeholder="Select an option"
                        :disabled="key < 1"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="signatoryOption > 1"
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    vid="event-custom-field-n"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="signatoryOption + '. Approved by'"
                      label-for="event-custom-field-n"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[signatoryOption - 1]"
                        label="name"
                        placeholder="Select an option"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :disabled="invalid"
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid"
                @click="enablePreview()"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview QCF Before Submitting</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
    <b-modal
      id="vendor-compare"
      ref="create-vendor-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Next"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add a Quotation"
      @ok="addNewVendor"
    >
      <b-form @submit.prevent="addNewVendor">
        <validation-observer
          v-if="vendorType === 1"
          ref="existingVendorForm"
        >
          <b-form-group
            label="Select an Existing Vendor*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Select an Existing Vendor"
              vid="vendor"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="vendor"
                label="companyName"
                placeholder="Select from the list"
                :options="vendorOptions"
                :reduce="companyName => companyName._id"
                :clearable="false"
              >
                <template #option="{ status, companyName }">
                  <span>{{ companyName }}</span> <span class="text-capitalize">({{ status }})</span>
                </template>

                <template #selected-option="{ status, companyName }">
                  <span>{{ companyName }}</span> <span class="text-capitalize"> ({{ status }})</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="link"
            class="mt-0"
            @click="changeVendorType(2)"
          >
            Add a New Vendor Instead
          </b-button>
          <b-form-group
            label="Does This Vendor Charge GST?"
            label-for="h-booking-gst"
            class="applicable_days"
          >
            <validation-provider
              #default="{ errors }"
              name="Does This Vendor Charge GST?"
              vid="gst"
              rules="required"
            >
              <b-form-radio-group
                id="h-booking-gst"
                v-model="gst"
                button-variant="outline-primary"
                buttons
                class="spaceBetweenRadio"
                multiple
              >
                <b-form-radio
                  v-for="(option, index) in gstOptions"
                  :key="index"
                  :value="option.value"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="gst === option.value"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="['GST Exclusive', 'GST Inclusive'].includes(gst)"
            label="GST*"
            label-for="h-booking-gst-percentage"
          >
            <validation-provider
              #default="{ errors }"
              name="GST"
              vid="gst-percentage"
              rules="required"
            >
              <b-form-input
                id="h-booking-gst-percentage"
                v-model="gstPercentage"
                name="gstPercentage"
                placeholder="7"
                class="mb-0"
                @keypress="validateNumberInput"
                @paste="validateNumberPaste"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
        <validation-observer
          v-else
          ref="newVendorForm"
        >
          <b-form-group
            label="Vendor's Name"
            label-for="h-booking-vendorname"
            class="mb-0"
          >
            <validation-provider
              name="Vendor's Name"
              vid="vendorName"
              rules="required"
            >
              <b-form-input
                id="h-booking-vendorname"
                v-model="vendorName"
                name="vendorName"
                placeholder="E.g. ABC Company"
                class="mb-0"
              />
            </validation-provider>
          </b-form-group>
          <b-button
            variant="link"
            class="mt-0"
            @click="changeVendorType(1)"
          >
            Select an Existing Vendor Instead
          </b-button>
          <b-form-group
            label="Does This Vendor Charge GST?"
            label-for="h-booking-gst"
            class="applicable_days"
          >
            <validation-provider
              #default="{ errors }"
              name="Does This Vendor Charge GST?"
              vid="gst"
              rules="required"
            >
              <b-form-radio-group
                id="h-booking-gst"
                v-model="gst"
                button-variant="outline-primary"
                buttons
                class="spaceBetweenRadio"
                multiple
              >
                <b-form-radio
                  v-for="(option, index) in gstOptions"
                  :key="index"
                  :value="option.value"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="gst === option.value"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="['GST Exclusive', 'GST Inclusive'].includes(gst)"
            label="GST*"
            label-for="h-booking-gst-percentage"
          >
            <validation-provider
              #default="{ errors }"
              name="GST"
              vid="gst-percentage"
              rules="required"
            >
              <b-form-input
                id="h-booking-gst-percentage"
                v-model="gstPercentage"
                name="gstPercentage"
                placeholder="7"
                class="mb-0"
                @keypress="validateNumberInput"
                @paste="validateNumberPaste"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="vendor-compare-update"
      ref="update-vendor-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Next"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add a Quotation"
      @ok="updateVendorData"
    >
      <b-form @submit.prevent="updateVendorData">
        <validation-observer
          v-if="vendorType === 1"
          ref="existingVendorForm"
        >
          <b-form-group
            label="Select an Existing Vendor*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Select an Existing Vendor"
              vid="vendor"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="vendor"
                label="companyName"
                placeholder="Select from the list"
                :options="vendorOptions"
                :reduce="companyName => companyName._id"
                :clearable="false"
              >
                <template #option="{ status, companyName }">
                  <span>{{ companyName }}</span> <span class="text-capitalize">({{ status }})</span>
                </template>

                <template #selected-option="{ status, companyName }">
                  <span>{{ companyName }}</span> <span class="text-capitalize"> ({{ status }})</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="link"
            class="mt-0"
            @click="changeVendorType(2)"
          >
            Add a New Vendor Instead
          </b-button>
          <b-form-group
            label="Does This Vendor Charge GST?"
            label-for="h-booking-gst"
            class="applicable_days"
          >
            <validation-provider
              #default="{ errors }"
              name="Does This Vendor Charge GST?"
              vid="gst"
              rules="required"
            >
              <b-form-radio-group
                id="h-booking-gst"
                v-model="gst"
                button-variant="outline-primary"
                buttons
                class="spaceBetweenRadio"
                multiple
              >
                <b-form-radio
                  v-for="(option, index) in gstOptions"
                  :key="index"
                  :value="option.value"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="gst === option.value"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="['GST Exclusive', 'GST Inclusive'].includes(gst)"
            label="GST*"
            label-for="h-booking-gst-percentage"
          >
            <validation-provider
              #default="{ errors }"
              name="GST"
              vid="gst-percentage"
              rules="required"
            >
              <b-form-input
                id="h-booking-gst-percentage"
                v-model="gstPercentage"
                name="gstPercentage"
                placeholder="7"
                class="mb-0"
                @keypress="validateNumberInput"
                @paste="validateNumberPaste"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
        <validation-observer
          v-else
          ref="newVendorForm"
        >
          <b-form-group
            label="Vendor's Name"
            label-for="h-booking-vendorname"
          >
            <validation-provider
              name="Vendor's Name"
              vid="vendorName"
              rules="required"
            >
              <b-form-input
                id="h-booking-vendorname"
                v-model="vendorName"
                name="vendorName"
                placeholder="E.g. ABC Company"
                class="mb-0"
              />
            </validation-provider>
          </b-form-group>
          <b-button
            variant="link"
            class="mt-0"
            @click="changeVendorType(1)"
          >
            Select an Existing Vendor Instead
          </b-button>
          <b-form-group
            label="Does This Vendor Charge GST?"
            label-for="h-booking-gst"
            class="applicable_days"
          >
            <validation-provider
              #default="{ errors }"
              name="Does This Vendor Charge GST?"
              vid="gst"
              rules="required"
            >
              <b-form-radio-group
                id="h-booking-gst"
                v-model="gst"
                button-variant="outline-primary"
                buttons
                class="spaceBetweenRadio"
                multiple
              >
                <b-form-radio
                  v-for="(option, index) in gstOptions"
                  :key="index"
                  :value="option.value"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="gst === option.value"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="['GST Exclusive', 'GST Inclusive'].includes(gst)"
            label="GST*"
            label-for="h-booking-gst-percentage"
          >
            <validation-provider
              #default="{ errors }"
              name="GST"
              vid="gst-percentage"
              rules="required"
            >
              <b-form-input
                id="h-booking-gst-percentage"
                v-model="gstPercentage"
                name="gstPercentage"
                placeholder="7"
                class="mb-0"
                @keypress="validateNumberInput"
                @paste="validateNumberPaste"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BBadge, BFormCheckbox, BCardBody, BImg, BTfoot, BCardHeader,
  BFormRadioGroup, BFormRadio, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormTextarea, BCardText, BDropdown, BDropdownItem, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store/index'
// import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    BFormTextarea,
    BCardBody,
    BCardText,
    BDropdown,
    BDropdownItem,
    BImg,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    vSelect,
    VueAutosuggest,
    // vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      user: store.state.auth.userData,
      quotation: {},
      gstPercentage: process.env.VUE_APP_GST_PERCENTAGE,
      vendorName: '',
      qcfPreview: false,
      secondAgree: '',
      vendor: '',
      gst: 'GST Inclusive',
      // gstPercentage: 7,
      vendorIndex: '',
      requesterName: '',
      deliveryDate: '',
      requesterDepartment: '',
      deliverTo: '',
      purpose: '',
      address: '',
      requestType: '',
      recommendedProvider: '',
      remarks: '',
      saveType: 'preview',
      signatoryOption: 4,
      signatoryOptionOld: 4,
      attachments: [],
      itemsMasterOptions: [],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      storeItemData: [],
      masterItemData: [],
      unitOptions: process.env.VUE_APP_UNIT_LIST.split(','),
      vendors: localStorage.getItem('vendors') ?? [],
      itemRequests: [],
      recommendedVendorOptions: [],
      deliveryCost: [],
      discountList: [],
      paymentTerms: [],
      gstList: [],
      filteredOptions: [],
      filteredUnitOptions: [],
      deliveryDateError: 'Valid Delivery Date is required',
      deliveryDateValidation: false,
      deliverToError: 'Valid Deliver To is required',
      deliverToValidation: false,
      purposeError: 'Valid Justification / Purpose is required',
      purposeValidation: false,
      addressError: 'Valid Address is required',
      addressValidation: false,
      requestTypeError: 'Valid Type of Request is required',
      requestTypeValidation: false,
      userOptions: [],
      vendorOptions: [],
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      vendorError: 'Valid Deliver To is required',
      vendorValidation: false,
      optionsChekcbox: [
        { text: 'ISO 9001 Quality Management', value: 'ISO 9001 Quality Management' },
        { text: 'ISO 14001 Environment Management', value: 'ISO 14001 Environment Management' },
        { text: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management', value: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management' },
        { text: 'No Certificate', value: 'No Certificate' },
        { text: 'Others', value: 'Others' },
      ],
      requestTypeOptions: [
        { text: 'Purchase Requisition Form (PRF)', value: 'Purchase Requisition Form (PRF)' },
        { text: 'Capital Expenditure Requisition Form (CAPEX)', value: 'Capital Expenditure Requisition Form (CAPEX)' },
      ],
      purchaseRequest: {},
      quantities: [],
      comparedVendors: [],
      signatoryOptions: [
        { title: '1', code: '1' },
        // { title: '2', code: '2' },
        // { title: '3', code: '3' },
        { title: '4', code: '4' },
        { title: '5', code: '5' },
        { title: '6', code: '6' },
        { title: '7', code: '7' },
        { title: '8', code: '8' },
        { title: '9', code: '9' },
        { title: '10', code: '10' },
      ],
      loas: [
        { title: '1. Prepared by', code: '1. Prepared by' },
        { title: '2. Requested by', code: '2. Requested by' },
        { title: '3. Verified by', code: '3. Verified by' },
        { title: '4. Recommended by', code: '4. Recommended by' },
        { title: '5. Agreed by', code: '5. Agreed by' },
        { title: '6. Certified by', code: '6. Certified by' },
        { title: '7. Confirmed by', code: '7. Confirmed by' },
        { title: '8. Supported by', code: '8. Supported by' },
        { title: '9. Validated by', code: '9. Validated by' },
      ],
      vendorType: 1,
      gstOptions: [
        { text: 'No GST', value: 'No GST' },
        { text: 'GST Exclusive', value: 'GST Exclusive' },
        { text: 'GST Inclusive', value: 'GST Inclusive' },
      ],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      fileName: 'compressed.tracemonkey-pldi-09.pdf',
      path: '/lib/pdfjs/web/viewer.html',
      pdfData: '',
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      showPDF: true,
      showAttachment: false,
      selectedFileName: 'PRF-000055',
      currentFileIndex: 1,
      imageSrc: '',
      lowestGrandTotal: [],
      budgetExpense: {},
      expenseItems: [],
      currentIndex: 0,
      itemsString: '',
      recommendedProviderCost: 0,

      // validation rules
      required,
    }
  },

  computed: {
    resolveSignatoryAssigns() {
      // eslint-disable-next-line eqeqeq
      if (this.signatoryOption == 1) {
        return this.signatoryAssigns
      }

      return [...this.signatoryAssigns].slice(0, -1)
    },
  },

  async created() {
    await this.$http.get('purchase/purchase-request/create/extra-options')
      .then(response => {
        this.itemsMasterOptions = response.data.itemsMasterOptions ?? []
        this.vendorOptions = response.data.vendorOptions ?? []
        this.userOptions = response.data.userOptions ?? []
        this.masterItemData = response.data.itemsMasterOptions
        this.storeItemData = response.data.inventoryItems
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    // for (let i = 1; i <= 100; i += 1) {
    //   this.quantities[i] = i
    // }
    this.$http.get(`purchase/quotations/purchase-request/${this.$route.params.parent}/detail`)
      .then(response => {
        this.purchaseRequest = response.data.purchaseRequest || {}
        this.budgetExpense = response.data.budgetExpense
        this.expenseItems = response.data.expenseItems
        this.quotation = response.data.quotation || {}
        // this.purchaseRequestForm = response.data.prf || {}
        // this.cpUserOptions = response.data.cpUserOptions || []

        // this.cpUser = this.purchaseRequest.assignedTo ? this.purchaseRequest.assignedTo._id : ''
        // this.purchaseRequestSignatories = response.data.purchaseRequestSignatories || []
        // this.purchaseRequestItems = response.data.purchaseRequestItems || []

        this.signatoryOption = this.quotation.signatories.length
        this.signatoryOptionOld = this.signatoryOption
        this.quotation.signatories.forEach(arrayItem => {
          this.signatoryAssigns.push(arrayItem.user._id)
        })
        this.selectedFileName = this.purchaseRequest.caseID
        const gstArray = []
        this.quotation.comparedVendors.forEach(arrayItem => {
          const vendor = this.vendorOptions.find(o => o._id === arrayItem.id._id)
          let status = 'not approved'
          if (vendor) {
            status = vendor.status
          }
          const vendorObject = {

            id: arrayItem.id._id, name: arrayItem.name, code: arrayItem.code, gstPercentage: arrayItem.gstPercentage ? arrayItem.gstPercentage : 7, type: 1, status,
          }
          this.comparedVendors.push(vendorObject)
          const vendorOpt = {

            id: `${arrayItem.id._id}|//|${arrayItem.name}`, companyName: arrayItem.name,
          }
          this.recommendedVendorOptions.push(vendorOpt)
          gstArray.push(vendorObject.gstPercentage)
        })

        this.deliveryCost = this.quotation.deliveryCost
        this.discountList = this.quotation.discountList
        this.paymentTerms = this.quotation.paymentTerms
        this.gstList = gstArray

        response.data.quotationItems.forEach(arrayItem => {
          this.itemRequests.push({
            searchInput: arrayItem.itemName,
            searchInputResult: false,
            storeItemSearchInputData: [],
            masterItemSearchInputData: [],
            searchResultNotFound: false,
            inventoryID: arrayItem.inventoryItem,
            sku: arrayItem.sku,
            id: arrayItem._id,
            itemsMaster: arrayItem.itemName,
            quantity: arrayItem.quantity,
            unit: arrayItem.unit,
            description: arrayItem.itemDescription,
            unitCost: arrayItem.unitCost,
            gst: arrayItem.gst,
          })
        })

        this.remarks = this.quotation.remarks
        this.recommendedProvider = `${this.quotation.recommendedProvider}|//|${this.quotation.recommendedProviderName}`
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    // const vendor = JSON.parse(localStorage.getItem('vendor'))
    // // console.log(vendor)
    // this.comparedVendors.push(vendor)
    // // localStorage.removeItem('vendor')
    // this.deliveryCost.push(0)
    // this.discountList.push(0)
    // this.paymentTerms.push('')
    // const vendorOpt = {}
    // vendorOpt.id = vendor.id ? (`${vendor.id}|//|${vendor.name}`) : `null|//|${vendor.name}`
    // vendorOpt.companyName = vendor.name
    // this.recommendedVendorOptions.push(vendorOpt)
  },
  beforeRouteLeave(to, from, next) {
    const bcLength = store.state.breadcrumb.breadcrumbs.length
    if (bcLength > 2) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
    }
    next()
  },

  // destroyed() {
  //   window.removeEventListener('resize', this.initTrHeight)
  // },
  methods: {
    canViewThisAction,
    resolveRecommendedProviderName() {
      const vendor = this.recommendedVendorOptions.find(o => o.id === this.recommendedProvider)
      if (vendor) {
        return vendor.companyName
      }

      return ''
    },
    checkVendor() {
      if (this.vendor) {
        this.$http.get(`purchase/items-master/${this.vendor}/items-options`)
          .then(response => {
            this.itemsMasterOptions = response.data.itemsMasterOptions ?? []
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    resolveNextSignatoryName() {
      const user = this.userOptions.find(o => o._id === this.signatoryAssigns[1])
      if (user) {
        return user.name
      }

      return ''
    },
    changeVendorType(value) {
      this.vendorType = value
    },
    saveAsDraft() {
      this.saveType = 'draft'
      this.submitForm()
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    closeSuggestionBox(index) {
      this.itemRequests[index].searchInputResult = false
    },
    submitSearchInput(index) {
      // eslint-disable-next-line no-param-reassign, no-return-assign
      this.itemRequests.forEach(o => o.searchInputResult = false)
      this.itemRequests[index].storeItemSearchInputData = this.storeItemData.filter(item => item.deletedAt === null && (item.name.toLowerCase().includes(this.itemRequests[index].searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.itemRequests[index].searchInput.toLowerCase())))
      this.itemRequests[index].masterItemSearchInputData = this.masterItemData.filter(item => item.description.toLowerCase().includes(this.itemRequests[index].searchInput.toLowerCase()))
      if (this.itemRequests[index].searchInput !== '') {
        this.itemRequests[index].searchInputResult = true
      } else {
        this.itemRequests[index].searchInputResult = false
      }
      if (!this.itemRequests[index].storeItemSearchInputData.length && !this.itemRequests[index].masterItemSearchInputData.length) {
        this.itemRequests[index].searchResultNotFound = true
        this.itemRequests[index].inventoryID = ''
        this.itemRequests[index].sku = ''
        this.itemRequests[index].itemsMaster = this.itemRequests[index].searchInput
      } else {
        this.itemRequests[index].searchResultNotFound = false
      }
    },
    addSearchInputStoreItem(masterIndex, index, id) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj._id === id)
        if (this.itemRequests.length) {
          const foundItemId = this.itemRequests.find(obj => obj.inventoryID === item._id)
          if (foundItemId) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'This item is already added!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            this.itemRequests[masterIndex].searchInput = item.name
            this.itemRequests[masterIndex].itemsMaster = item.name
          } else {
            this.itemRequests[masterIndex].inventoryID = item._id
            this.itemRequests[masterIndex].sku = item.sku
            // this.itemRequests[masterIndex].inventoryID = item._id
            this.itemRequests[masterIndex].searchInput = item.name
            this.itemRequests[masterIndex].itemsMaster = item.name
            this.itemRequests[masterIndex].searchInputResult = false
          }
        } else {
          this.itemRequests[masterIndex].inventoryID = item._id
          this.itemRequests[masterIndex].sku = item.sku
          // this.itemRequests[masterIndex].inventoryID = item._id
          this.itemRequests[masterIndex].searchInput = item.name
          this.itemRequests[masterIndex].itemsMaster = item.name
          this.itemRequests[masterIndex].searchInputResult = false
        }
      }
    },
    addSearchInputMasterItem(masterIndex, index, id) {
      if (this.masterItemData.length) {
        const item = this.masterItemData.find(masterItemObj => masterItemObj._id === id)
        if (item) {
          this.itemRequests[masterIndex].inventoryID = ''
          this.itemRequests[masterIndex].sku = ''
          this.itemRequests[masterIndex].unit = item.unit
          this.itemRequests[masterIndex].searchInput = item.description
          this.itemRequests[masterIndex].itemsMaster = item.description
          this.itemRequests[masterIndex].searchInputResult = false
        }
      }
    },
    addItemToQuotation() {
      const unitCostArray = []
      const gstArray = []
      this.comparedVendors.forEach(vendor => {
        unitCostArray.push(0)
        if (vendor.code === 'GST Inclusive' || vendor.code === 'GST Exclusive') {
          gstArray.push('yes')
        } else {
          gstArray.push('no')
        }
      })
      this.itemRequests.push({
        searchInput: '',
        searchInputResult: false,
        storeItemSearchInputData: [],
        masterItemSearchInputData: [],
        searchResultNotFound: false,
        inventoryID: '',
        sku: '',
        id: null,
        itemsMaster: '',
        quantity: 1,
        description: '',
        unitCost: unitCostArray,
        gst: gstArray,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.purchaseRequest.caseID
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.attachments[key - 2].name
        if (this.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.attachments[key - 2].data)
          // }
          if (!this.attachments[key - 2].data.startsWith('http')) {
            const base64Raw = this.attachments[key - 2].data.split(',')[1]
            const pdfDataJS = this.base64ToUint8Array(base64Raw)
            const pdfViewerFrame2 = document.getElementById('attachmentpdf')
            // pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${pdfDataJS}`)
            // eslint-disable-next-line func-names
            pdfViewerFrame2.onload = () => {
              pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
            }
            pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          } else {
            const pdfViewerFrame2 = document.getElementById('attachmentpdf')
            pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.attachments[key - 2].data}`)
          }
          this.showPDF = true
        } else {
          this.imageSrc = this.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    async enablePreview() {
      const newMeta = {
        active: true,
        text: 'Preview QCF',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()
      const element = document.getElementById('pdfGenerateDiv')
      const width = document.getElementById('main-table-width').offsetWidth * 0.2645833333 + 50
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          useCORS: true,
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', format: [width, 210], orientation: 'l' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS = vm.base64ToUint8Array(base64Raw)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          vm.currentFileIndex = 1
          vm.selectedFileName = vm.purchaseRequest.caseID
          vm.showAttachment = false
          window.scrollTo(0, 0)
          vm.qcfPreview = true
          store.commit('appConfig/UPDATE_LOADER', false)
        })
    },
    async calculateTotalPage() {
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const tableFooterHeight = document.getElementById('tableFooter').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = 0
      let totalItems = this.itemRequests.length
      // let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      while (totalItems > 0) {
        contentHeight = 1000 - (logoHeight + tableFooterHeight + footerHeight)
        // console.log(contentHeight)
        this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          // contentHeight += tableFooterHeight
        }
        // // console.log(tableHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          // console.log(contentHeight, tableHeight)
          // eslint-disable-next-line eqeqeq
          if (sliceStart == sliceEnd) {
            tableHeight = 0
          } else {
            this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
            // eslint-disable-next-line no-await-in-loop
            await this.$nextTick()
            tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
          }
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkItemRequests.length)
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        // i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      // if (this.pdfTotalPage === 1) {
      //   contentHeight = 940 - (logoHeight + tableFooterHeight + footerHeight)
      // } else {
      //   contentHeight = 940 - (logoHeight + footerHeight)
      // }
      // const finalHeight = document.getElementById('content-div').offsetHeight
      // if (finalHeight > contentHeight) {
      //   this.pdfTotalPage += 1
      // }
      // let remainingHeight = 0
      // const dHeight = 1000
      // // if (this.isMobile()) {
      // //   dHeight = 1120
      // // }
      // let finalDataHeight = 0
      // if (this.pdfTotalPage === 1) {
      //   finalDataHeight = document.getElementById('content-div').offsetHeight
      // } else {
      //   finalDataHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
      // }
      // this.gapHeight = 0
      // remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      // if (remainingHeight > 0) {
      //   this.gapHeight = remainingHeight
      // }
      // if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
      //   this.pdfTotalPage += 1
      //   this.gapHeight = dHeight - (logoHeight + footerHeight)
      // }
    },
    enableEdit() {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      this.qcfPreview = false
    },
    finalConfirmation() {
      const minVal = Math.min(...this.lowestGrandTotal)
      if (minVal >= 2000 && this.comparedVendors.length < 3) {
        this.$swal({
          title: 'Proceed without 3 quotations?',
          html: 'The selected quote has a value that exceeds S$2,000. It is recommended that at least 3 quotations are provided for effective quote comparison. Are you sure you want to proceed to the next step?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'Continue to next step',
          cancelButtonText: 'Save as draft and continue later',
          confirmButtonText: 'Continue to edit',
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
            denyButton: 'btn btn-outline-warning ml-1',
          },
          allowOutsideClick: false,
          buttonsStyling: false,
          width: '800px',
        })
          .then(result => {
            if (result.isConfirmed) {
              //
            } else if (result.isDenied) {
              this.submitForm()
            } else {
              this.saveAsDraft()
            }
          })
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.signatoryOption > 1) {
          this.$swal({
            title: 'Submit this QCF?',
            html: `You are proceeding to sign this QCF Form. The form will then be ready for ${this.resolveNextSignatoryName()} for signing.`,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/fly.png'),
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: 'No, go back',
            confirmButtonText: 'Yes, Proceed',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                this.submitForm()
              }
            })
        } else {
          this.$swal({
            title: 'Submit this QCF?',
            html: 'You are proceeding to sign this QCF Form.',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/fly.png'),
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: 'No, go back',
            confirmButtonText: 'Yes, Proceed',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                this.submitForm()
              }
            })
        }
      }
    },
    resetPreviousVendorData() {
      this.vendorType = 1
      this.gst = 'GST Inclusive'
      this.vendor = ''
      this.vendorName = ''
    },
    getSubTotal(key) {
      const vendor = this.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.itemRequests.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.itemRequests.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.discountList[key] ? this.discountList[key] : 0
      const dCost = this.deliveryCost[key] ? this.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    setMasterItem(suggestionItem, _suggestionIndex, index) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.itemRequests[index].itemsMaster = suggestionItem.item.description
        const masterItem = this.itemsMasterOptions.find(o => o.description === suggestionItem.item.description)
        if (masterItem) {
          this.itemRequests[index].unit = masterItem.unit
        }
      }
    },
    suggestOnInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.itemsMasterOptions.filter(item => item.description.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.description
    },
    suggestOnInputChangeUnit(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.unitOptions.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredUnitOptions = [{
        data: filteredData,
      }]
    },
    getUnitSuggestionValue(suggestion) {
      return suggestion.item
    },
    setUnit(suggestionItem, _suggestionIndex, index) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.itemRequests[index].unit = suggestionItem.item
      }
    },
    getGST(key) {
      // eslint-disable-next-line no-nested-ternary
      const gstPercentageVal = this.gstList[key] ? this.gstList[key] : 7
      const vendor = this.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.itemRequests.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(gstPercentageVal))) * parseFloat(gstPercentageVal))
        // console.log(parseFloat(gstPercentageVal))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.itemRequests.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((gstPercentageVal / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      const total = ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
      this.lowestGrandTotal[key] = total
      return total
    },
    changeItemsMaster(index) {
      this.itemRequests[index].description = this.itemsMasterOptions[index].description
      this.itemRequests[index].unit = this.itemsMasterOptions[index].unit
    },
    removeItem(index) {
      if (this.itemRequests.length > 1) {
        this.itemRequests.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeVendor(index) {
      if (this.comparedVendors.length > 1) {
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are about to remove this vendor from quotation comparison.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: 'Custom Icon',
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              const vendor = this.recommendedVendorOptions[index]
              if (this.recommendedProvider && this.recommendedProvider === vendor.id) {
                this.recommendedProvider = ''
              }
              this.comparedVendors.splice(index, 1)
              this.deliveryCost.splice(index, 1)
              this.discountList.splice(index, 1)
              if (this.gstList[index]) {
                this.gstList.splice(index, 1)
              }
              this.paymentTerms.splice(index, 1)
              this.recommendedVendorOptions.splice(index, 1)
              this.itemRequests.forEach(item => {
                item.unitCost.splice(index, 1)
                item.gst.splice(index, 1)
              })
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    resolveSignatoryName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveSignatoryDepartment(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    updateVendor(index) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'You are about to update this vendor information.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure.",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const vendor = this.comparedVendors[index]
            if (vendor.type === 1) {
              this.vendor = vendor.id
              this.vendorType = 1
            } else {
              this.vendorName = vendor.name
              this.vendorType = 2
            }
            this.gst = vendor.code
            this.gstPercentage = vendor.gstPercentage
            this.vendorIndex = index
            this.$refs['update-vendor-modal'].toggle('#toggle-btn')
          }
        })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async changeSignatory() {
      // eslint-disable-next-line radix
      this.signatoryOption = parseInt(this.signatoryOption)
      if (this.signatoryOptionOld > this.signatoryOption) {
        // const { searchEl } = this.$refs.vSelectSignatoryOption
        // if (searchEl) {
        //   searchEl.blur()
        // }
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are changing the number of signatory.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryOptionOld = this.signatoryOption
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            } else {
              this.signatoryOption = this.signatoryOptionOld
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            }
          })
      } else {
        this.signatoryOptionOld = this.signatoryOption
        // eslint-disable-next-line radix
        const intOption = parseInt(this.signatoryOption)
        if (this.signatoryAssigns.length > intOption) {
          this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
        }
      }
    },
    addNewVendor(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.vendorType === 1) {
        this.$refs.existingVendorForm.validate().then(success => {
          if (success) {
            const exists = this.comparedVendors.find(o => o.id === this.vendor)
            if (exists) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'This vendor is already in comparison list.',
                  icon: 'BellIcon',
                  variant: 'warning',
                },
              })
            } else {
              const selected = this.vendorOptions.find(o => o._id === this.vendor)
              const vendor = {
                id: this.vendor, name: selected.companyName, code: this.gst, gstPercentage: this.gstPercentage, type: 1, status: selected.status,
              }
              this.comparedVendors.push(vendor)
              const vendorOpt = {}
              vendorOpt.id = vendor.id ? (`${vendor.id}|//|${vendor.name}`) : `null|//|${vendor.name}`
              vendorOpt.companyName = vendor.name
              this.recommendedVendorOptions.push(vendorOpt)
              this.$refs['create-vendor-modal'].toggle('#toggle-btn')

              this.deliveryCost.push(0)
              this.discountList.push(0)
              this.paymentTerms.push('')
              if (this.gst === 'GST Inclusive' || this.gst === 'GST Exclusive') {
                this.gstList.push(this.gstPercentage)
              } else {
                this.gstList.push(0)
              }
              this.itemRequests.forEach(item => {
                item.unitCost.push(0)
                if (this.gst === 'GST Inclusive' || this.gst === 'GST Exclusive') {
                  item.gst.push('yes')
                } else {
                  item.gst.push('no')
                }
              })
            }
          }
        })
      } else {
        this.$refs.newVendorForm.validate().then(success => {
          if (success) {
            const vendor = {
              id: null, name: this.vendorName, code: this.gst, gstPercentage: this.gstPercentage, type: 2, status: 'not approved',
            }

            this.comparedVendors.push(vendor)

            const vendorOpt = {}
            vendorOpt.id = vendor.id ? (`${vendor.id}|//|${vendor.name}`) : `null|//|${vendor.name}`
            vendorOpt.companyName = vendor.name
            this.recommendedVendorOptions.push(vendorOpt)

            this.deliveryCost.push(0)
            this.discountList.push(0)
            this.paymentTerms.push('')
            if (this.gst === 'GST Inclusive' || this.gst === 'GST Exclusive') {
              this.gstList.push(this.gstPercentage)
            } else {
              this.gstList.push(0)
            }
            this.itemRequests.forEach(item => {
              item.unitCost.push(0)
              if (this.gst === 'GST Inclusive' || this.gst === 'GST Exclusive') {
                item.gst.push('yes')
              } else {
                item.gst.push('no')
              }
            })
            this.$refs['create-vendor-modal'].toggle('#toggle-btn')
          }
        })
      }
    },

    updateVendorData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.recommendedProvider = ''
      if (this.vendorType === 1) {
        this.$refs.existingVendorForm.validate().then(success => {
          if (success) {
            const exists = this.comparedVendors.filter(o => o.id === this.vendor)
            if (exists.length > 1) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'This vendor is already in comparison list.',
                  icon: 'BellIcon',
                  variant: 'warning',
                },
              })
            } else {
              const selected = this.vendorOptions.find(o => o._id === this.vendor)
              const vendor = {
                id: this.vendor, name: selected.companyName, code: this.gst, gstPercentage: this.gstPercentage, type: 1, status: selected.status,
              }
              this.$set(this.comparedVendors, this.vendorIndex, vendor)

              const vendorOpt = {}
              vendorOpt.id = vendor.id ? (`${vendor.id}|//|${vendor.name}`) : `null|//|${vendor.name}`
              vendorOpt.companyName = vendor.name
              this.$set(this.recommendedVendorOptions, this.vendorIndex, vendorOpt)

              if (this.gst === 'No GST') {
                this.gstList[this.vendorIndex] = 0
                this.itemRequests.forEach(item => {
                  // eslint-disable-next-line no-param-reassign
                  item.gst[this.vendorIndex] = 'no'
                })
              }
              if (this.gst === 'GST Inclusive' || this.gst === 'GST Exclusive') {
                this.gstList[this.vendorIndex] = this.gstPercentage
                this.itemRequests.forEach(item => {
                  // eslint-disable-next-line no-param-reassign
                  item.gst[this.vendorIndex] = 'yes'
                })
              }

              this.$refs['update-vendor-modal'].toggle('#toggle-btn')
            }
          }
        })
      } else {
        this.$refs.newVendorForm.validate().then(success => {
          if (success) {
            const vendor = {
              id: null, name: this.vendorName, code: this.gst, gstPercentage: this.gstPercentage, type: 2, status: 'not approved',
            }

            this.$set(this.comparedVendors, this.vendorIndex, vendor)

            const vendorOpt = {}
            vendorOpt.id = vendor.id ? (`${vendor.id}|//|${vendor.name}`) : `null|//|${vendor.name}`
            vendorOpt.companyName = vendor.name
            this.$set(this.recommendedVendorOptions, this.vendorIndex, vendorOpt)

            if (this.gst === 'No GST') {
              this.gstList[this.vendorIndex] = 0
              this.itemRequests.forEach(item => {
                // eslint-disable-next-line no-param-reassign
                item.gst[this.vendorIndex] = 'no'
              })
            }
            if (this.gst === 'GST Inclusive' || this.gst === 'GST Exclusive') {
              this.gstList[this.vendorIndex] = this.gstPercentage
              this.itemRequests.forEach(item => {
                // eslint-disable-next-line no-param-reassign
                item.gst[this.vendorIndex] = 'yes'
              })
            }

            this.$refs['update-vendor-modal'].toggle('#toggle-btn')
          }
        })
      }
    },
    submitForm() {
      this.$refs.quotationCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          let gstVal = 0
          const index = this.recommendedVendorOptions.findIndex(o => o.id === this.recommendedProvider)
          let recommendedProviderCost = 0
          let recommendedProviderSubTotal = 0
          let recommendedProviderGST = 0
          if (index !== -1) {
            recommendedProviderCost = this.getGrandTotal(index)
            recommendedProviderSubTotal = this.getSubTotal(index)
            recommendedProviderGST = this.getGST(index)
            const vendor = this.comparedVendors[index]
            if (vendor) {
              if (vendor.code !== 'No GST') {
                gstVal = vendor.gstPercentage
              }
            }
          }

          let itemString = ''
          this.itemRequests.forEach(item => {
            if (itemString === '') {
              itemString += item.itemsMaster
            } else {
              itemString += `, ${item.itemsMaster}`
            }
          })

          formData.append('url', window.location.origin)
          formData.append('vendorIndex', index)
          formData.append('recommendedProvider', this.recommendedProvider)
          formData.append('itemString', itemString)
          formData.append('recommendedProviderCost', recommendedProviderCost)
          formData.append('recommendedProviderSubTotal', recommendedProviderSubTotal)
          formData.append('recommendedProviderGST', recommendedProviderGST)
          formData.append('remarks', this.remarks)
          formData.append('gstPercentage', gstVal)
          formData.append('comparedVendors', JSON.stringify(this.comparedVendors))
          const prItems = this.itemRequests.map(o => ({
            id: o.id,
            inventoryItem: o.inventoryID,
            sku: o.sku,
            itemsMaster: o.searchInput,
            description: o.description,
            unit: o.unit,
            quantity: o.quantity,
            unitCost: o.unitCost,
            gst: o.gst,
          }))
          formData.append('itemRequests', JSON.stringify(prItems))
          formData.append('deliveryCost', JSON.stringify(this.deliveryCost))
          formData.append('discountList', JSON.stringify(this.discountList))
          formData.append('gstList', JSON.stringify(this.gstList))
          formData.append('paymentTerms', JSON.stringify(this.paymentTerms))
          formData.append('signatoryOption', this.signatoryOption)
          formData.append('status', this.saveType)
          formData.append('requestID', this.$route.params.parent)
          formData.append('signatoryAssigns', JSON.stringify(this.signatoryAssigns))

          this.$http.patch(`purchase/quotations/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                if (this.saveType === 'preview') {
                  if (this.signatoryOption > 1) {
                    this.$swal({
                      title: 'Submitted for Approval',
                      html: `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.resolveNextSignatoryName()}) for signing.`,
                      // eslint-disable-next-line global-require
                      imageUrl: require('@/assets/images/icons/success.png'),
                      imageWidth: 80,
                      imageHeight: 80,
                      imageAlt: 'Custom Icon',
                      showCancelButton: false,
                      allowOutsideClick: false,
                      confirmButtonText: 'View Entry',
                      customClass: {
                        confirmButton: 'btn btn-primary mr-1',
                        cancelButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                      .then(result => {
                        if (result.value) {
                          this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.parent } })
                        }
                      })
                  } else {
                    this.$swal({
                      title: 'QCF Approved',
                      html: `${this.purchaseRequest.caseID} is now ready for Purchase Order creation by Central Purchasing.`,
                      // eslint-disable-next-line global-require
                      imageUrl: require('@/assets/images/icons/success.png'),
                      imageWidth: 80,
                      imageHeight: 80,
                      imageAlt: 'Custom Icon',
                      showCancelButton: false,
                      allowOutsideClick: false,
                      confirmButtonText: 'View Entry',
                      customClass: {
                        confirmButton: 'btn btn-primary mr-1',
                        cancelButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                      .then(result => {
                        if (result.value) {
                          this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.parent } })
                        }
                      })
                  }
                } else {
                  this.$swal({
                    title: 'Draft Saved',
                    html: 'You can continue editing this QCF at a later time before sending it out for approval.',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        localStorage.removeItem('vendor')
                        this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.parent } })
                      }
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'deliveryDate') {
                    this.deliveryDateError = validationError.msg
                    this.deliveryDateValidation = true
                  } else if (validationError.param === 'deliverTo') {
                    this.deliverToError = validationError.msg
                    this.deliverToValidation = true
                  } else if (validationError.param === 'purpose') {
                    this.purposeError = validationError.msg
                    this.purposeValidation = true
                  } else if (validationError.param === 'address') {
                    this.addressError = validationError.msg
                    this.addressValidation = true
                  } else if (validationError.param === 'requestType') {
                    this.requestTypeError = validationError.msg
                    this.requestTypeValidation = true
                  } else if (validationError.param === 'certificates') {
                    this.certificatesError = validationError.msg
                    this.certificatesValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
</style>
